import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Sky from 'react-sky'

const notFound = (props) => {
  return (
    <>
      <Helmet>
        <title>{'404 - Not Found'}</title>
      </Helmet>
      <div style={{ textAlign: 'center', padding: '5vh 0', lineHeight: '1.5' }}>
        <Sky
          images={{
            0: '/assets/planets/generic-icon.png',
            1: '/assets/planets/mercury-icon.png',
            2: '/assets/planets/venus-icon.png',
            3: '/assets/planets/earth-icon.png',
            4: '/assets/planets/mars-icon.png',
            5: '/assets/planets/jupiter-icon.png',
            6: '/assets/planets/saturn-icon.png',
            7: '/assets/planets/uranus-icon.png',
            8: '/assets/planets/neptune-icon.png',
            9: '/assets/meteor-showers/comet-icon.png',
            10: '/assets/meteor-showers/planetarium-icon.png',
            11: '/assets/deep-sky/nebula-icon.png'
          }}
          how={40}
          time={20}
          size={'60px'}
        />
        <p>That's written in the stars. Lets go back <Link to='/de'>home</Link> safely.</p>
      </div>
    </>
  )
}

export default notFound
